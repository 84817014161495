












































































































import { Component, Vue } from 'vue-property-decorator';
declare let window: any;
declare let chrome: any;
import ChromeExtensionReviewCounts from '@/pages/import/components/ChromeExtensionReviewCounts.vue';
import ChromeExtensionReview from '@/pages/import/components/ChromeExtensionReview.vue';
import { sendViaRelay } from '../browserextension/plasmo';

interface ICheckExtensionInstalledResponse {
  installed: boolean;
}

@Component({
  components: {
    ChromeExtensionReviewCounts,
    ChromeExtensionReview,
  },
})
export default class ImportChromeExtensionPage extends Vue {
  extensioninstalled = false;
  // https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome
  get isChrome() {
    // @ts-ignore
    return (
      !!window.chrome && window.navigator.vendor === 'Google Inc.'
    );
  }

  async mounted() {
    if (this.isChrome) {
      this.extensioninstalled =
        await this.checkAnyExtensionInstalled();
    }
  }

  //Cross Browser Messaging
  async checkExtensionInstalled(
    extensionId: string,
  ): Promise<boolean> {
    try {
      const resp = (await sendViaRelay({
        name: 'check-extension-installed',
        body: { action: 'checkExtensionInstalled' },
        extensionId: extensionId,
      })) as ICheckExtensionInstalledResponse;

      if (resp) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  async checkAnyExtensionInstalled() {
    const productionextensionid = 'pnibcoleffbpdiohejiinidocmklphde';
    const devextensionid = 'enokkbajfbldemnehjfjkhfgghenocap';
    let extensionInstalled = false;
    extensionInstalled = await this.checkExtensionInstalled(
      productionextensionid,
    );

    console.log('checkExtensionInstalledProd', extensionInstalled);

    if (!extensionInstalled) {
      extensionInstalled = await this.checkExtensionInstalled(
        devextensionid,
      );
      console.log('checkExtensionInstalledDev', extensionInstalled);
    }

    return extensionInstalled;
  }
}
