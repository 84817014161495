






















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ChromeExtensionReview extends Vue {
  @Prop() reviewerimage!: boolean;
  @Prop() reviewer!: boolean;
  @Prop() review!: boolean;
}
